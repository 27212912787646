<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('thirdParty_reportReview.otherDocs') }}
    </div>
    <!-- <ag-grid
      ref="docGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    /> -->
    <document
      ref="document"
      :detail-data="detailData"
    />
    <div slot="footer">
      <el-button @click="currentShowFlag=false">
        {{ $t('operation.close') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import document from '../../dealer/document/list'
export default {
  components: {
    document
  },
  props: {
    showFlag: { type: Boolean, default: false },
    detailData: { type: Object, default: () => { return {} } }
  },
  data () {
    return {}
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    }
  },
  methods: {
    handleDialogOpen () {
      // this.$nextTick(() => {
      this.$refs.document.handleDataSearch()
      // })
    },
    handleDialogClosed () {}
  }
}

// import Vue from 'vue'

// const BASEURL = {
//   list: '/jlr/tt/supportDocument/list',
//   download: '/system/attachment/download'
// }

// /** 附件下载 */
// const Document = Vue.extend({
//   inject: ['handleDataSearch', 'handleExportClick'],
//   template: `<div style="height:100%;text-align:center;">
//       <el-tooltip effect="light" :content="$t('operation.export')">
//         <el-button type="text" style="font-size:14px;" icon="el-icon-download" :disabled="!params.data.attachment"
//           @click="handleExportClick(params.data)" />
//       </el-tooltip>
//     </div>`
// })

// export default {
//   name: 'OtherDocs',
//   props: {
//     showFlag: { type: Boolean, default: false },
//     detailData: { type: Object }
//   },
//   data () {
//     return {
//       tableData: [],
//       gridOptions: {},
//       frameworkComponents: {}
//     }
//   },
//   provide () {
//     return {
//       handleDataSearch: this.handleDataSearch,
//       handleExportClick: this.handleExportClick
//     }
//   },
//   computed: {
//     currentShowFlag: {
//       get () {
//         return this.showFlag
//       },
//       set (val) {
//         this.$emit('update:showFlag', val)
//       }
//     },
//     columnDefs () {
//       return [
//         {
//           headerName: this.$t('dealer_document.needed'),
//           field: 'name',
//           minWidth: 200
//         },
//         {
//           headerName: this.$t('dealer_document.actual'),
//           field: 'attachment.fileName',
//           cellRenderer: params => {
//             return params.data.attachment ? `${params.data.attachment.fileName}.${params.data.attachment.fileType}` : ''
//           },
//           minWidth: 320
//         },
//         {
//           headerName: this.$t('config_group.createBy'),
//           field: 'createBy.name',
//           minWidth: 120
//         },
//         {
//           headerName: this.$t('config_group.createDate'),
//           field: 'createDate',
//           minWidth: 160
//         },
//         {
//           headerName: this.$t('config_group.updateBy'),
//           field: 'updateBy.name',
//           minWidth: 120
//         },
//         {
//           headerName: this.$t('config_group.updateDate'),
//           field: 'updateDate',
//           minWidth: 120
//         },
//         {
//           headerName: this.$t('operation.download'),
//           suppressSizeToFit: true,
//           width: 68,
//           cellRenderer: 'Document'
//         }
//       ]
//     }
//   },
//   beforeMount () {
//     this.frameworkComponents = { Document: Document }
//   },
//   methods: {
//     handleDataSearch () {
//       const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
//       this.$axios
//         .post(BASEURL.list, { period: this.detailData.period, dealer: { id: this.detailData.dealer.id } })
//         .then(resp => {
//           const respData = resp.data
//           this.tableData = respData.list
//         })
//         .finally(() => {
//           loadingFlag.close()
//         })
//     },
//     handleExportClick (row) {
//       this.$utils.fileSaveAs(BASEURL.download, { id: row.attachment.id })
//     },
//     handleDialogOpen () {
//       this.handleDataSearch()
//     },
//     handleDialogClosed () {
//       this.tableData = []
//     }
//   }
// }
</script>
