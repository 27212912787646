<template>
  <div class="page__wrapper">
    <ag-grid
      ref="docGrid"
      edit-type="fullRow"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'dealer_document:add'"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <simple-upload
      ref="uploader"
      url="/system/attachment/upload"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/jlr/tt/supportDocument/list',
  save: '/jlr/tt/supportDocument/save',
  update: '/jlr/tt/supportDocument/update',
  delete: '/jlr/tt/supportDocument/delete',
  download: '/system/attachment/download'
}

const Codes = ['d001', 'd002', 'd003', 'd004', 'd005']

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [ 'handleDataSearch', 'handleSaveClick', 'handleDeleteClick', 'handleImportClick', 'handleExportClick' ],
  computed: {
    isBaseData () { return Codes.indexOf(this.params.data.code) > -1 }
  },
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" v-permission="'dealer_document:save'"
          :disabled="isBaseData" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.cancel')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-refresh-left" v-permission="'dealer_document:save'"
          @click="handleDataSearch" :disabled="isBaseData" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" icon="el-icon-delete" style="font-size:14px;" v-permission="'dealer_document:delete'"
          @click="handleDeleteClick(params.data)" :disabled="isBaseData" />
      </el-tooltip>
    </div>`
})

/** 附件上传下载列 */
const Document = Vue.extend({
  inject: ['handleDataSearch', 'handleSaveClick', 'handleDeleteClick', 'handleImportClick', 'handleExportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.import')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-upload" v-permission="'dealer_document:import'"
          :disabled="!params.data.name" @click="handleImportClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.export')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-download" v-permission="'dealer_document:export'"
          :disabled="!params.data.attachment" @click="handleExportClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerDocument',
  props: {
    detailData: { type: Object }
  },
  data () {
    return {
      searchModel: { period: this.$moment().subtract(1, 'months').format('YYYYMM') },
      tableData: [],
      currentData: {},
      gridOptions: {},
      isShowUploader: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick,
      handleImportClick: this.handleImportClick,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    dealerId () {
      return this.detailData && this.detailData.dealer ? this.detailData.dealer.id : this.$store.state.app.userInfo.company.id
    },
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_document.month'),
          hidden: this.detailData,
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        { slotName: 'tableOperation', col: this.detailData ? { xs: 24, sm: 24, md: 24 } : { xs: 20, sm: 20, md: 20 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_document.needed'),
          field: 'name',
          editable: params => {
            return Codes.indexOf(params.data.code) < 0
          },
          cellEditorParams: {
            rules: { required: true, message: this.$t('validate.isRequired') }
          },
          minWidth: 360
        },
        {
          headerName: this.$t('dealer_document.actual'),
          field: 'attachment.fileName',
          cellRenderer: params => {
            return params.data.attachment ? `${params.data.attachment.fileName}.${params.data.attachment.fileType}` : ''
          },
          minWidth: 360
        },
        {
          headerName: this.$t('dealer_document.uploadDownload'),
          suppressSizeToFit: true,
          width: 100,
          cellRenderer: 'Document'
        },
        {
          headerName: this.$t('config_group.createBy'),
          field: 'createBy.name',
          minWidth: 120
        },
        {
          headerName: this.$t('config_group.createDate'),
          field: 'createDate',
          minWidth: 160
        },
        {
          headerName: this.$t('config_group.updateBy'),
          field: 'updateBy.name',
          minWidth: 120
        },
        {
          headerName: this.$t('config_group.updateDate'),
          field: 'updateDate',
          minWidth: 160
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation, Document: Document }
  },
  mounted () {
    // 如果从报表审阅进入，handleDataSearch 在 dialog 打开时触发
    if (!this.detailData) this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      let params = {}
      params.dealer = { id: this.dealerId }
      // 本页面在报表审阅页面被引用，支持接收行数据 detailData
      if (this.detailData) {
        params.period = this.detailData.period
      } else {
        params.period = this.searchModel.period || this.$moment().subtract(1, 'months').format('YYYYMM')
      }
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.tableData.push({})
      this.$nextTick(() => {
        this.$refs.docGrid.focusOnCell(this.tableData.length - 1, 'name')
      })
    },
    handleSaveClick (row) {
      this.$refs.docGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitModel = this.$_.cloneDeep(row.data)
          submitModel.period = this.detailData ? this.detailData.period : this.searchModel.period
          submitModel.dealer = { id: this.dealerId }
          this.$axios
            .post(row.data.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDeleteClick (row) {
      if (!row.id) return this.handleDataSearch()
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleExportClick (row) {
      this.$utils.fileSaveAs(BASEURL.download, { id: row.attachment.id })
    },
    handleImportClick (row) {
      this.currentData = row
      this.isShowUploader = true
    },
    handleUploadSuccess (response, file, fileList) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      let submitData = this.$_.cloneDeep(this.currentData)
      submitData.attachment = { id: response.data.id }
      submitData.period = this.detailData ? this.detailData.period : this.searchModel.period
      submitData.dealer = { id: this.dealerId }
      this.$axios
        .post(BASEURL.update, submitData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.uploadSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
          this.isShowUploader = false
          this.handleDataSearch()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
